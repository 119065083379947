/* Copyright G. Hemingway, @2021 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Pile } from "./pile";
import styled from "styled-components";
import {ModalNotify} from "./shared";
import * as cloneDeep from 'lodash/cloneDeep';

let locations = ["undo", "redo", "stack1", "stack2", "stack3", "stack4", "draw", "discard",
    "pile1", "pile2", "pile3", "pile4", "pile5", "pile6", "pile7"]

const values = ["ace", '2', '3', '4', '5', '6', '7', '8', '9', '10', "jack", "queen", "king"];
const validSrc = ["pile1", "pile2", "pile3", "pile4", "pile5", "pile6", "pile7"];

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

const GameBase = styled.div`
  grid-row: 2;
  grid-column: sb / main;
  background-image: linear-gradient(to bottom, lightgreen, lightpink, white);
`;

export const Game = ({ match, history }) => {
  let [state, setState] = useState({
    pile1: [],
    pile2: [],
    pile3: [],
    pile4: [],
    pile5: [],
    pile6: [],
    pile7: [],
    stack1: [],
    stack2: [],
    stack3: [],
    stack4: [],
    draw: [],
    discard: [],
  });
  // let [target, setTarget] = useState(undefined);
  // let [startDrag, setStartDrag] = useState({ x: 0, y: 0 });

  let [move, setMove] = useState(undefined);
  let [undoStack, setUndoStack] = useState({ stack: [] });
  let [redoStack, setRedoStack] = useState({ stack: [] });
  let [notify, setNotify] = useState("");
  let [notifyEndgame, setNotifyEndgame] = useState("");
  let [firstRender, setFirstRender] = useState(true);
  let [endFound, setEndFound] = useState(false);
  let [undoPoints, setUndoPoints] = useState({stack: []});
  let [redoPoints, setRedoPoints] = useState({stack: []});

  useEffect(async () => {
    if(state.stack1.length === values.length
        && state.stack2.length === values.length
        && state.stack3.length === values.length
        && state.stack4.length === values.length)
    setNotify(`Congratulations! You beat the game. Would you like to end the game?`);
  }, [state["stack1"], state["stack2"], state["stack3"], state["stack4"]])

  useEffect( async () => {
    if(!firstRender && !endFound){
      let temp = cloneDeep(state);
      let card1 = temp.stack1[state.stack1.length - 1];
      let card2 = temp.stack2[state.stack2.length - 1];
      let card3 = temp.stack3[state.stack3.length - 1];
      let card4 = temp.stack4[state.stack4.length - 1];
      let stackCards = [card1, card2, card3, card4];

      if(card1.value !== "king" || card2.value !== "king"
          || card3.value !== "king" || card4.value !== "king"){
        for(let i = 0; i < 4; ++i){
          if(stackCards[i].nonexistent){
            stackCards[i].value = "ace";
          }else{
            let index = values.indexOf(stackCards[i].value) + 1;
            if(index != -1){
              stackCards[i].value = values[index];
            }
          }
        }
        let found = false;
        for(let i = 0; i < validSrc.length; ++i){
          if(temp[validSrc[i]][temp[validSrc[i]].length - 1] !== undefined){
            if(temp[validSrc[i]][temp[validSrc[i]].length - 1].up){
              let card = state[validSrc[i]][temp[validSrc[i]].length - 1];
              for(let i = 0; i < stackCards.length; ++i){
                if(card.value === "ace" && stackCards[i].value === "ace"){
                  found = true;
                  break;
                }else if(card.value === stackCards[i].value && card.suit === stackCards[i].suit){
                  found = true;
                  break;
                }
              }
            }
          }
        }
        if(!found){
          for(let i = 0; i < temp.draw.length; ++i){
            let card = temp.draw[i];
            for(let j = 0; j < stackCards.length; ++j){
              if(card !== undefined && stackCards[j] !== undefined){
                if(card.value === "ace" && stackCards[j].value === "ace") {
                  found = true;
                  break;
                }else if(card.value === stackCards[j].value && card.suit === stackCards[j].suit){
                  found = true;
                  break;
                }
              }
            }
          }
        }
        if(!found){
          for(let i = 0; i < temp.discard.length; ++i){
            let card = temp.discard[i];
            for(let j = 0; j < stackCards.length; ++j){
              if(card !== undefined && stackCards[j] !== undefined){
                if(card.value === "ace" && stackCards[j].value === "ace") {
                  found = true;
                  break;
                }else if(card.value === stackCards[j].value && card.suit === stackCards[j].suit){
                  found = true;
                  break;
                }
              }
            }
          }
        }
        if(found){
          console.log("There is a playable move to the foundations");
        }else{
          setEndFound(true);
          setNotifyEndgame(`No playable moves to the foundations found. Would you like to end the game?`);
        }
      }
    }
    setFirstRender(false);
  }, [state])

  const getGameData = async (moveData) => {
    try{
      let res = await fetch(`/v1/game/${match.params.id}`, {
        body: JSON.stringify(moveData),
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
      });
      let gameData = await res.json();
      return gameData;
    }catch (err) {
      console.log(err.err);
      return err;
    }
  }

  useEffect( async () => {
    if(move !== undefined){
      if(move.dst !== undefined){
        let oldState = state;
        console.log(move);
        let newState = await getGameData(move);
        if(!newState.err){
          setState({
            pile1: newState.pile1,
            pile2: newState.pile2,
            pile3: newState.pile3,
            pile4: newState.pile4,
            pile5: newState.pile5,
            pile6: newState.pile6,
            pile7: newState.pile7,
            stack1: newState.stack1,
            stack2: newState.stack2,
            stack3: newState.stack3,
            stack4: newState.stack4,
            draw: newState.draw,
            discard: newState.discard,
          });
          let temp = undoStack.stack;
          temp.push(oldState);
          setUndoStack({ stack: temp });
          setRedoStack({ stack: [] });

          // let tmp = undoPoints.stack;
          // if(move.dst === "stack1" || move.dst === "stack2" || move.dst === "stack3"
          //     || move.dst === "stack4"){
          //   temp.push(10);
          // }else if(move.src === "discard" && (move.dst === "pile1" && move.dst === "pile2"
          //     && move.dst === "pile3" && move.dst === "pile4" && move.dst === "pile5"
          //     && move.dst === "pile6" && move.dst === "pile7")){
          //   temp.push(5);
          // }


          // setUndoPoints({stack: tmp})

        }else{
          console.log(newState.err);
        }
        setMove(undefined);
      }
    }
  }, [move])

  useEffect( () => {
    const getGameState = async () => {
      const response = await fetch(`/v1/game/${match.params.id}`);
      const data = await response.json();
      setState({
        pile1: data.pile1,
        pile2: data.pile2,
        pile3: data.pile3,
        pile4: data.pile4,
        pile5: data.pile5,
        pile6: data.pile6,
        pile7: data.pile7,
        stack1: data.stack1,
        stack2: data.stack2,
        stack3: data.stack3,
        stack4: data.stack4,
        draw: data.draw,
        discard: data.discard,
      });
    };
    getGameState();
  }, [match.params.id]);


  // reset the move if user clicks on background
  const backgroundClick = (ev) => {
    if(locations.indexOf(ev.target.parentNode.id) === -1){
      console.log("move reset");
      setMove(undefined);
    }
  }

  const onClick = (ev) => {

    // obtain target and parent information
    let target = ev.target;
    let parentID = ev.target.parentNode.id;
    let cards = state[parentID];

    // make set of cards including and below the clicked card
    const selectCards = (cards, target) => {
      let set = [];
      let index = cards.length;
      let suit = target.id.substr(0, target.id.indexOf(':'));
      let value = target.id.substr(target.id.indexOf(':') + 1);
      for(let i = 0; i < cards.length; ++i){
        if(i > index){
          set.push({"suit": cards[i].suit, "value": cards[i].value, "up": true})
        }else if(cards[i].suit === suit && cards[i].value === value){
          if(cards[i].up === false && parentID !== "draw"){
            console.log("Can't click on face-down card");
            return undefined;
          }
          index = i;
          set.push({"suit": cards[i].suit, "value": cards[i].value, "up": true})
        }
      }
      return set;
    }

    let selectedCards = selectCards(cards, target);
    if(selectedCards === undefined && parentID != 'draw'){
      return;
    }

    if(move === undefined){
      if(parentID === "draw"){
        setMove({
          card: selectedCards,
          src: parentID,
          dst: "discard"
        })
      }else{
        setMove({
          card: selectedCards,
          src: parentID
        })
      }
    }else{
      setMove({
        card: move.card,
        src: move.src,
        dst: parentID
      })
    }
  };

  const undo = async (ev) => {
    if(undoStack.stack.length !== 0){
      try{
        let previousState = state;
        let oldState = undoStack.stack[undoStack.stack.length - 1];
        setState({
          pile1: oldState.pile1,
          pile2: oldState.pile2,
          pile3: oldState.pile3,
          pile4: oldState.pile4,
          pile5: oldState.pile5,
          pile6: oldState.pile6,
          pile7: oldState.pile7,
          stack1: oldState.stack1,
          stack2: oldState.stack2,
          stack3: oldState.stack3,
          stack4: oldState.stack4,
          draw: oldState.draw,
          discard: oldState.discard,
        });
        let tempUndo = undoStack.stack;
        tempUndo.pop();
        setUndoStack({ stack: tempUndo });

        let tempRedo = redoStack.stack;
        tempRedo.push(previousState);
        setRedoStack({ stack: tempRedo });

        console.log(undoStack);

        await fetch(`/v1/game/undo/${match.params.id}`, {
          body: JSON.stringify(oldState),
          method: "PUT",
          headers: {
            "content-type": "application/json",
          },
        });
      }catch (err) {
        console.log(err.err);
      }
    }else{
      console.log("No moves left to undo")
    }
  }

  const redo = async (ev) => {
    if(redoStack.stack.length !== 0){
      try{
        let previousState = state;
        let newState = redoStack.stack[redoStack.stack.length - 1];
        setState({
          pile1: newState.pile1,
          pile2: newState.pile2,
          pile3: newState.pile3,
          pile4: newState.pile4,
          pile5: newState.pile5,
          pile6: newState.pile6,
          pile7: newState.pile7,
          stack1: newState.stack1,
          stack2: newState.stack2,
          stack3: newState.stack3,
          stack4: newState.stack4,
          draw: newState.draw,
          discard: newState.discard,
        });
        console.log(redoStack);
        let temp = redoStack.stack;
        temp.pop();
        setRedoStack({ stack: temp });

        let tempTwo = undoStack.stack;
        tempTwo.push(previousState);
        setUndoStack({ stack: tempTwo });

        console.log(redoStack);

        await fetch(`/v1/game/undo/${match.params.id}`, {
          body: JSON.stringify(newState),
          method: "PUT",
          headers: {
            "content-type": "application/json",
          },
        });
      }catch (err) {
        console.log(err.err);
      }
    }else{
      console.log("No moves left to redo")
    }
  }

  const autocomplete = async (ev) => {
    let oldState;
    let newState;
    do{
      oldState = await getGameState();
      for (let i = 1; i <= 7; ++i) {
        let pile = "pile" + i;
        let lastIndex = oldState[pile].length - 1;
        let card = oldState[pile][lastIndex];
        if(lastIndex >= 0){
          let move = {
            card: [card],
            src: pile,
            dst: ""
          }
            for (let j = 1; j <= 4; ++j) {
              let stack = "stack" + j;
              move.dst = stack;
              let newState = await getGameData(move);
              if (!newState.err) {
                await setState({
                  pile1: newState.pile1,
                  pile2: newState.pile2,
                  pile3: newState.pile3,
                  pile4: newState.pile4,
                  pile5: newState.pile5,
                  pile6: newState.pile6,
                  pile7: newState.pile7,
                  stack1: newState.stack1,
                  stack2: newState.stack2,
                  stack3: newState.stack3,
                  stack4: newState.stack4,
                  draw: newState.draw,
                  discard: newState.discard,
                });
                break;
              }
          }
        }
      }
      newState = await getGameState();
    }while(JSON.stringify(newState) !== JSON.stringify(oldState));
  }

  const getGameState = async () => {
    const response = await fetch(`/v1/game/${match.params.id}`);
    let data = await response.json();
    let newState = {
      pile1: data.pile1,
      pile2: data.pile2,
      pile3: data.pile3,
      pile4: data.pile4,
      pile5: data.pile5,
      pile6: data.pile6,
      pile7: data.pile7,
      stack1: data.stack1,
      stack2: data.stack2,
      stack3: data.stack3,
      stack4: data.stack4,
      draw: data.draw,
      discard: data.discard,
    }
    return newState;
  }


  const onAcceptRegister = async () => {
    setNotify("");
    setNotifyEndgame("");
    await fetch(`/v1/game/end/${match.params.id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
    });
    history.push(`/login`)
  };

  const onDeclineRegister = async () => {
    setNotifyEndgame("");
    setNotify("");
  };

  return (
    <GameBase id="gamebase" onClick={backgroundClick}>
      <button id="undo" type="button" onClick={undo}>Undo</button>
      <button id="redo" type="button" onClick={redo}>Redo</button>
      <button id="autocomplete" type="button" onClick={autocomplete}>Autocomplete</button>
      {notify !== "" ? (
          <ModalNotify
              id="notification"
              msg={notify}
              onAccept={onAcceptRegister}
              onDecline={onDeclineRegister}
              option1={"yes"}
              option2={"no"}
              style={{color: 'limegreen', zIndex: 10, position: 'fixed'}}
          />
      ) : null}
      {notifyEndgame !== "" ? (
          <ModalNotify
              id="notification"
              msg={notifyEndgame}
              onAccept={onAcceptRegister}
              onDecline={onDeclineRegister}
              option1={"yes"}
              option2={"no"}
              style={{color: 'limegreen', zIndex: 10, position: 'fixed'}}
          />
      ) : null}
      <CardRow>
        <Pile id="stack1" selectedCards={move === undefined ? undefined : move.card}
              cards={state.stack1} spacing={0} onClick={onClick} />
        <Pile id="stack2" selectedCards={move === undefined ? undefined : move.card}
              cards={state.stack2} spacing={0} onClick={onClick} />
        <Pile id="stack3" selectedCards={move === undefined ? undefined : move.card}
              cards={state.stack3} spacing={0} onClick={onClick} />
        <Pile id="stack4" selectedCards={move === undefined ? undefined : move.card}
              cards={state.stack4} spacing={0} onClick={onClick} />
        <CardRowGap />
        <Pile id="draw" selectedCards={move === undefined ? undefined : move.card}
              cards={state.draw} spacing={0} onClick={onClick} />
        <Pile id="discard" selectedCards={move === undefined ? undefined : move.card}
              cards={state.discard} spacing={0} onClick={onClick} />
      </CardRow>
      <CardRow>
        <Pile id="pile1" selectedCards={move === undefined ? undefined : move.card}
              cards={state.pile1} onClick={onClick} />
        <Pile id="pile2" selectedCards={move === undefined ? undefined : move.card}
              cards={state.pile2} onClick={onClick} />
        <Pile id="pile3" selectedCards={move === undefined ? undefined : move.card}
              cards={state.pile3} onClick={onClick} />
        <Pile id="pile4" selectedCards={move === undefined ? undefined : move.card}
              cards={state.pile4} onClick={onClick} />
        <Pile id="pile5" selectedCards={move === undefined ? undefined : move.card}
              cards={state.pile5} onClick={onClick} />
        <Pile id="pile6" selectedCards={move === undefined ? undefined : move.card}
              cards={state.pile6} onClick={onClick} />
        <Pile id="pile7" selectedCards={move === undefined ? undefined : move.card}
              cards={state.pile7} onClick={onClick} />
      </CardRow>
    </GameBase>
  );
};

Game.propTypes = {
  match: PropTypes.object.isRequired,
};
