/* Copyright G. Hemingway, @2021 - All rights reserved */
"use strict";

import React from "react";
import styled from "styled-components";

const LandingBase = styled.div`

  justify-content: center;
  grid-area: main;
`;

export const Landing = () => (
  <LandingBase>
    <h1>Jason Kim's Solitaire Website</h1>
      <h3>About</h3>
      <ul>
          <li>A solitaire website developed using HTML, CSS, JavaScript, React, Node.js, and Mongoose</li>
          <li>Hosted on Amazon Web Services</li>
          <li>Server is connected to a MongoDB database run through Docker</li>
      </ul>
      <h3>Features</h3>
      <ul>
          <li>The ability to play Klondike Solitaire</li>
          <li>Undo/Redo buttons</li>
          <li>A pop-up message that will indicate whether there are no more playable moves to the foundation piles</li>
      </ul>
      <h3>To Play:</h3>
      <ul>
          <li>Register an account and log in</li>
          <li>Alternatively, you can use the public guest account:</li>

          <h4>Username: guest123</h4>
          <h4>Password: Guest123!</h4>
      </ul>
  </LandingBase>
);
