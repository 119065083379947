/* Copyright G. Hemingway, @2021 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    ErrorMessage,
    FormBase,
    FormInput,
    FormLabel,
    FormButton,
    ModalNotify,
} from "./shared";
import { validPassword, validUsername } from "../../shared";

export const Edit = ({ history }) => {
    let [state, setState] = useState({
        username: "",
        first_name: "",
        last_name: "",
        city: "",
    });
    let [error, setError] = useState("");
    let [notify, setNotify] = useState("");

    const onChange = (ev) => {
        setError("");
        // Update from form and clear errors
        setState({
            ...state,
            [ev.target.name]: ev.target.value,
        });
        // Make sure the username is valid
        if (ev.target.name === "username") {
            let usernameInvalid = validUsername(ev.target.value);
            if (usernameInvalid) setError(`Error: ${usernameInvalid.error}`);
        }
    };

    const onSubmit = async (ev) => {
        ev.preventDefault();
        // Only proceed if there are no errors
        if (error !== "") return;
        const res = await fetch("/v1/user", {
            method: "PUT",
            body: JSON.stringify(state),
            credentials: "include",
            headers: {
                "content-type": "application/json",
            },
        });
        if (res.ok) {
            // Notify users
            setNotify(`${state.username} User information updated.`);
        } else {
            const err = await res.json();
            setError(err.error);
        }
    };

    const onAcceptRegister = () => {
        history.push("/login");
    };

    return (
        <div style={{ gridArea: "main" }}>
            {notify !== "" ? (
                <ModalNotify
                    id="notification"
                    msg={notify}
                    onAccept={onAcceptRegister}
                    option1={"Ok"}
                />
            ) : null}
            <ErrorMessage msg={error} />
            <h3>Edit Profile Information</h3>
            <FormBase>
                <FormLabel htmlFor="first_name">First Name:</FormLabel>
                <FormInput
                    id="first_name"
                    name="first_name"
                    placeholder="Change First Name"
                    onChange={onChange}
                    value={state.first_name}
                />

                <FormLabel htmlFor="last_name">Last Name:</FormLabel>
                <FormInput
                    id="last_name"
                    name="last_name"
                    placeholder="Change Last Name"
                    onChange={onChange}
                    value={state.last_name}
                />

                <FormLabel htmlFor="city">City:</FormLabel>
                <FormInput
                    id="city"
                    name="city"
                    placeholder="Change City"
                    onChange={onChange}
                    value={state.city}
                />
                <div />
                <FormButton id="submitBtn" onClick={onSubmit}>
                    Update Information
                </FormButton>
            </FormBase>
        </div>
    );
};

Edit.propTypes = {
    history: PropTypes.object.isRequired,
};
